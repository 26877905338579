#nav-wrapper {
    background-color: var(--grey-2);
    border-left: 5px solid var(--blue-4);
    padding: 1%;
    margin: 0px;
    border-radius: 0px;
    display: flex;
    justify-content: space-between;
    position: sticky;
    top: 3px;
    opacity: 95%;
    box-shadow:0px 0px 10px 0px var(--grey-1);
}

#right-nav {
    display: flex;
    justify-content: right;
    align-items: center;
}

#left-nav{
    display: flex;
    justify-content: left;
    align-items: center;
}

.link {
    margin: 0%;
    padding: 4px;
    padding-left: 25px;
    font-weight: bold;
    text-decoration: none;
    color: var(--cool-grey-6);
    text-shadow: 1px 1px 4px var(--cool-grey-2);
}

.link.home-link {
    padding-left: 0px;
    font-weight: bolder;
    font-size: 160%;
    color: var(--cool-grey-6);
}

.link:hover {
    color: var(--blue-6);
    text-shadow: 3px 3px 7px var(--cool-grey-2);
}

.link:active {
    color: var(--blue-3);
    text-shadow: none;
}

.link.active:not(.home-link) {
    text-decoration: underline var(--blue-5);
    font-size: 110%;
}