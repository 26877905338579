/* :root {
  --primary-color: #3498db;
  --secondary-color: #2ecc71;
  --accent-color: #e74c3c;
  --background-color: #b8bec3;
  --text-color: #333333;
} */

:root {
  --primary-color: #d4a373;
  --secondary-color: #faedcd;
  --accent-color: #fafae0;
  --background-color: #ccd5ae;
  --secondary-background-color: #e9edc9;
  --text-color: #333333;
  --blue-1: hsl(215, 90%, 10%);
  --blue-2: hsl(215, 75%, 25%);
  --blue-3: hsl(215, 55%, 35%);
  --blue-4: hsl(215, 50%, 50%);
  --blue-5: hsl(215, 55%, 65%);
  --blue-6: hsl(215, 75%, 80%);
  --blue-7: hsl(215, 90%, 90%);
  --grey-1: hsl(0, 0%, 15%);
  --grey-2: hsl(0, 0%, 25%);
  --grey-3: hsl(0, 0%, 45%);
  --grey-4: hsl(0, 0%, 65%);
  --grey-5: hsl(0, 0%, 75%);
  --grey-6: hsl(0, 0%, 85%);
  --grey-7: hsl(0, 0%, 93%);
  --cool-grey-1: hsl(210, 20%, 15%);
  --cool-grey-2: hsl(210, 15%, 25%);
  --cool-grey-3: hsl(210, 10%, 45%);
  --cool-grey-4: hsl(210, 10%, 65%);
  --cool-grey-5: hsl(210, 10%, 75%);
  --cool-grey-6: hsl(210, 15%, 85%);
  --cool-grey-7: hsl(210, 20%, 93%);
  --warm-grey-1: hsl(40, 19%, 15%);
  --warm-grey-2: hsl(40, 15%, 25%);
  --warm-grey-3: hsl(40, 10%, 45%);
  --warm-grey-4: hsl(40, 10%, 65%);
  --warm-grey-5: hsl(40, 10%, 75%);
  --warm-grey-6: hsl(40, 15%, 85%);
  --warm-grey-7: hsl(40, 20%, 93%);
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

body {
  background-color: var(--grey-6);
  background-image: url('C:/Users/18193/GithubRepo/tmethot01.github.io/public/background-image-4.png');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  background-attachment: fixed;
  font-family: Helvetica, Arial, sans-serif;
}
