#about-wrapper {
    display: flex;
    justify-content: center;
    padding: 1%;
}

#about {
    display: flex;
    flex-direction: column;
    align-content: center;
    color: var(--text-color);
    /*height: 100vh;  not mobile friendly */
    border-radius: 0px;
    background-color: var(--secondary-background-color);
    opacity: 1;
    width: 60%;
    justify-content: flex-start;
    box-shadow:1px 2px 10px 0px var(--text-color);
    line-height: 1.5;
}

h1 {
    text-align: center;
    opacity: 1;
    font-weight: bold;
    letter-spacing: -2%;
}

#intro-wrapper {
    display: flex;
    justify-content: center;
    flex-direction: column;
    width: 75%;
    align-items: center;
    align-self: center;
}

.intro {
    color: var(--text-color);
    font-size: 120%;
    width: 30em;
}